import axios from 'axios'

// export const BASE_URL = 'https://test.ringosupply.com:8010/'; 
export const BASE_URL = 'https://orders.ringosupply.com/'; 
// export const BASE_URL = 'http://158.160.159.191:3333/'; 
// export const BASE_URL = 'http://localhost:3333/'; 
// export const BASE_URL = 'http://192.168.78.61:8000/'; 
// export const BASE_URL = 'http://192.168.31.248:8000/'; 
// export const BASE_URL = 'https://admin.ringosupply.com/'; 

export const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
})

instance.interceptors.request.use(config => {
    const token = JSON.parse(localStorage.getItem('persist:auth')).token
    if (token){
        config.headers.Authorization = `Bearer ${token.replaceAll('"','')}`
    }
    return config
    }, error => {
        return Promise.reject(error)
})
