import {AppBar, Box, Button, Container, Toolbar, Typography} from '@mui/material';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { logout } from '../store/authSlice';


function Header(){

    const {
        isAuthenticated
    } = useSelector(store => store.authorization)

    const dispatch = useDispatch()

    const menu = [
        {id: 1, path: '/', text: 'Dashboard'},
        {id: 2, path: '/sales/table', text: 'Sales table'},
        {id: 3, path: '/sales/analytic', text: 'Sales analytic'},
        {id: 4, path: '/sales/average_check', text: 'Average check'},
    ]

    return(
        <Box flexGrow={1}>
            <AppBar position='static'>
                <Container maxWidth='xl'>
                    <Toolbar disableGutters sx={{p: 1}}>
                        <Typography 
                            variant='h3'
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                                Analytic tool
                        </Typography>
                        <Box flexGrow={1} sx={{ 
                            display: isAuthenticated ? 'flex' : 'none', 
                            gap: '15px' 
                        }}>
                            {isAuthenticated && menu.map( elem => 
                                <Link to={elem.path} key={elem.id}>
                                    <Button variant="contained">{elem.text}</Button>
                                </Link>
                            )}
                        </Box>
                        <Box>
                            {isAuthenticated &&
                                <Button onClick={() => dispatch(logout())} variant="contained">Logout</Button> 
                            }
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    )
}




export default Header