import {combineReducers, configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import ordersSlice from './ordersSlice'
import saleFilterSlice from './saleFilterSlice'
import productFilter from './productFilter'
import salesAnalyticFilterSlice from './salesAnalyticFilterSlice'
import avgCheckFilterSlice from './avgCheckFilterSlice'
import authSlice from './authSlice'
import {
    persistStore,
    persistReducer
  } from 'redux-persist'
import storage from 'redux-persist/lib/storage';

const authPersistConfig = {
    key: 'auth',
    storage,
    blacklist: ['authLoad', 'error'],
}

const persistConfig = {
    key: 'root',
    storage,
    whilelist: ['saleFilter'],
    blacklist: ['_persist']
}

const rootReducer = combineReducers({
    saleFilter: saleFilterSlice,
    productFilter: productFilter,
    salesAnalyticFilter: salesAnalyticFilterSlice,
    avgCheckFilterSlice: avgCheckFilterSlice,
    orders: ordersSlice,
    authorization: persistReducer(authPersistConfig, authSlice),
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
      })
})


export const persistor = persistStore(store)

