import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { flexCenter, whiteBorder } from "../service/styles"
import { Box, Button, Slider } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { setDateType, setHourseRange } from "../store/salesAnalyticFilterSlice"
import { salesDataAnalytic } from "../asyncActions/ordersAction"





function SalesAnalyticFilter(){

    const minDistance = 1

    const marks = new Array(24).fill(null)
                .map((_, ind) => ({value: ind, label: `${ind}h`}))
                .filter(elem => elem.value % 3 === 0 )

    const dispatch = useDispatch()

    const {
        dateFrom,
        dateTo,
        dateCompareTo,
        dateCompareFrom,
        hourseRange,
        loadData
    } = useSelector(store => store.salesAnalyticFilter)

    const handleChange = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }
        if (activeThumb === 0) {
            dispatch(setHourseRange([Math.min(newValue[0], hourseRange[1] - minDistance), hourseRange[1]]))
        } else {
            dispatch(setHourseRange([hourseRange[0], Math.max(newValue[1], hourseRange[0] + minDistance)]))
        }
    };


    function handleSubmit(){
        let dateBetween1 = {
           dateTo: dateTo.format('YYYY-MM-DD'),
           dateFrom: dateFrom.format('YYYY-MM-DD') 
        }

        let dateBetween2 = {
            dateTo: dateCompareTo.format('YYYY-MM-DD'),
            dateFrom: dateCompareFrom.format('YYYY-MM-DD')   
        }
        dispatch(salesDataAnalytic({dateBetween1, dateBetween2}))
    }
    

    return(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={whiteBorder}>
                    <Box sx={flexCenter}>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: 5}}>
                            <DatePicker 
                                label='Date from'
                                value={dateFrom}
                                onChange={(date) => dispatch(setDateType({value: 'dateFrom', date}))}
                            />
                            <DatePicker 
                                label='Date to'
                                value={dateTo}
                                onChange={(date) => dispatch(setDateType({value: 'dateTo', date}))}
                            />
                            </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: 5}} >
                            <DatePicker 
                                label='Date from'
                                value={dateCompareFrom}
                                onChange={(date) => dispatch(setDateType({value: 'dateCompareFrom', date}))}
                            />
                            <DatePicker 
                                label='Date to'
                                value={dateCompareTo}
                                onChange={(date) => dispatch(setDateType({value: 'dateCompareTo', date}))}
                            />
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Box  sx={{width: 300}}>
                            <Slider
                                value={hourseRange}
                                onChange={handleChange}
                                valueLabelDisplay="auto"
                                max={23}
                                min={0}
                                marks={marks}
                                disableSwap
                            />
                        </Box>
                    </Box> 
                    <Box sx={flexCenter}>
                        <Button disabled={loadData} sx={{justifySelf: 'start'}} variant="outlined" onClick={handleSubmit}>Submit</Button>
                    </Box>
                </Box>
        </LocalizationProvider>
    )
}


export default SalesAnalyticFilter

