import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { productData } from "../asyncActions/ordersAction";
import { getHourlySales } from "../service/getHourlySale";


export const productFilterSlice = createSlice({
    name: 'productFilter',
    initialState: {
        dateTo: dayjs(),
        dateFrom: dayjs(),
        hourseRange: [0, 12],
        data: [],
        loadData: false,
        error: null
    },
    reducers: {
        setDateType(state, action){
            state[action.payload.value] = action.payload.date
        },
        setHourseRange(state, action){
            state.hourseRange = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(productData.fulfilled,((state, action) => {
                state.loadData = false
                state.data = getHourlySales(action.payload, state.hourseRange)
                
                state.error = null
            }))
            .addCase(productData.rejected, ((state) => {
                state.data = {}
                state.loadData = false
                state.error = 'error'
            }))
            .addCase(productData.pending, ((state) => {
                state.loadData = true
                state.error = null
            }))
    }
})


export const {
    setDateType,
    setHourseRange
} = productFilterSlice.actions
export default productFilterSlice.reducer
