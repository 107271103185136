import { BASE_URL, instance } from "./interceptors";


export const orderUnit = async (params) => {
    const body = {
        date_to: params.dateTo,
        date_from: params.dateFrom,
    }
    const response = await instance.post(`${BASE_URL}api/orders`, body);
    return response.data;
};
