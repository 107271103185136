import dayjs from "dayjs";
import { routes } from "./routes";
import { RouterProvider } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./store";

function App() {  
  return (
    <div>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={routes}/>
      </PersistGate>

    </div>
  );
}

export default App;
