import { Box, Button } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { flexCenter, whiteBorder } from "../service/styles"
import { useDispatch, useSelector } from "react-redux"
import { setDateType } from "../store/avgCheckFilterSlice"
import { averageCheck } from "../asyncActions/ordersAction"






function AverageCheckFilter(){

    const dispatch = useDispatch()

    const {
        dateFrom,
        dateTo,
        dateCompareFrom,
        dateCompareTo,
        loadData
    } = useSelector(store => store.avgCheckFilterSlice)


    function handleSubmit(){
        let dateBetween1 = {
           dateTo: dateTo.format('YYYY-MM-DD'),
           dateFrom: dateFrom.format('YYYY-MM-DD') 
        }

        let dateBetween2 = {
            dateTo: dateCompareTo.format('YYYY-MM-DD'),
            dateFrom: dateCompareFrom.format('YYYY-MM-DD')   
        }
        dispatch(averageCheck({dateBetween1, dateBetween2}))
    }


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={whiteBorder}>
                    <Box sx={flexCenter}>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: 5}}>
                            <DatePicker 
                                label='Date from'
                                value={dateFrom}
                                onChange={(date) => dispatch(setDateType({value: 'dateFrom', date}))}
                            />
                            <DatePicker 
                                label='Date to'
                                value={dateTo}
                                onChange={(date) => dispatch(setDateType({value: 'dateTo', date}))}
                            />
                            </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: 5}} >
                            <DatePicker 
                                label='Date from'
                                value={dateCompareFrom}
                                onChange={(date) => dispatch(setDateType({value: 'dateCompareFrom', date}))}
                            />
                            <DatePicker 
                                label='Date to'
                                value={dateCompareTo}
                                onChange={(date) => dispatch(setDateType({value: 'dateCompareTo', date}))}
                            />
                        </Box>
                    </Box>
                    <Box sx={flexCenter}>
                        <Button disabled={loadData} sx={{justifySelf: 'start'}} variant="outlined" onClick={handleSubmit}>Submit</Button>
                    </Box>
                </Box>
        </LocalizationProvider>
    )
}

export default AverageCheckFilter