import axios from "axios";
import { BASE_URL, instance } from "./interceptors";


export const loginUnit = async (credentials) => {
    try {
      const response = await axios.post(`${BASE_URL}api/auth/login`, credentials);
      return response.data;
    } catch (error) {
      throw error;
    }
};


export const checkTokenUnit = async () => {
    const token = JSON.parse(localStorage.getItem('persist:auth'))?.token    
    let response = await instance.get(`${BASE_URL}api/auth/verify`,{token: token.replaceAll('"','')})
    return response
} 
