import { createSlice } from "@reduxjs/toolkit";
import { loginAction } from "../asyncActions/authActions";


export const authSlice = createSlice({
    name: 'authSlice',
    initialState: {
        token: null,
        error: null,
        authLoad: false,
        isAuthenticated: false,
    },
    reducers: {
        logout(state){
            state.token = null
            state.isAuthenticated = false
            localStorage.removeItem('persist:auth')
        }
    },
    extraReducers: (bulder) => {
        bulder
            .addCase(loginAction.pending, (state) => {
                state.authLoad = true
                state.error = null
            })
            .addCase(loginAction.fulfilled, (state, action) => {
                state.authLoad = false
                const {
                    token
                } = action.payload
                state.token = token
                state.error = null
                state.isAuthenticated = true
            })
            .addCase(loginAction.rejected, (state, action) => {            
                state.error = action.payload?.detail && 'some error'
                state.isAuthenticated = false
                state.authLoad = false

            })
    }
})




export default authSlice.reducer

export const {
    logout
} = authSlice.actions