import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import allProducts from '../service/requared_products.json'
import { salesDataAnalytic } from "../asyncActions/ordersAction";
import { calculateSalesAnalytic } from "../service/calculateSalesAnalytic";


export const salesAnalyticFilterSlice = createSlice({
    name: 'salesAnalyticFilter',
    initialState: {
        dateFrom: dayjs().startOf('month'),
        dateTo: dayjs(),
        dateCompareFrom: dayjs(),
        dateCompareTo: dayjs(),
        hourseRange: [0, 12],
        data: [],
        loadData: false,
        error: null
    },
    reducers: {
        setDateType(state, action){
            state[action.payload.value] = action.payload.date
        },
        setHourseRange(state, action){
            state.hourseRange = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(salesDataAnalytic.fulfilled,((state, action) => {
            state.loadData = false
            state.data = calculateSalesAnalytic(allProducts, action.payload.resData1, action.payload.resData2, state.hourseRange)           
            state.error = null
        }))
        .addCase(salesDataAnalytic.rejected, ((state) => {
            state.data = {}
            state.loadData = false
            state.error = 'error'
        }))
        .addCase(salesDataAnalytic.pending, ((state) => {
            state.loadData = true
            state.error = null
        }))
    }
})


export const {
    setDateType,
    setHourseRange
} = salesAnalyticFilterSlice.actions
export default salesAnalyticFilterSlice.reducer
