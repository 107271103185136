import { Box, Button, Slider } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { flexCenter, whiteBorder } from "../service/styles";
import { useDispatch, useSelector } from "react-redux";
import { setDateType, setHourseRange } from "../store/productFilter";
import { productData } from "../asyncActions/ordersAction";


const marks = new Array(24).fill(null)
                .map((_, ind) => ({value: ind, label: `${ind}h`}))
                .filter(elem => elem.value % 3 === 0 )

function ProductFilter(){
    const {
        dateTo,
        dateFrom,
        hourseRange,
        loadData
    } = useSelector(store => store.productFilter)

    const minDistance = 1;
        
    function valuetext(value) {
        return `${value}°C`;
    }


    const handleChange1 = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }
        if (activeThumb === 0) {
            dispatch(setHourseRange([Math.min(newValue[0], hourseRange[1] - minDistance), hourseRange[1]]))
        } else {
            dispatch(setHourseRange([hourseRange[0], Math.max(newValue[1], hourseRange[0] + minDistance)]))
        }
    };


    const dispatch = useDispatch()
        
    function handleSubmit(){ 
        const formatDateTo = dateTo.format('YYYY-MM-DD')
        const formatDateFrom = dateFrom.format('YYYY-MM-DD')
        dispatch(productData({dateFrom: formatDateFrom, dateTo: formatDateTo}))
    }


    return(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={whiteBorder}>
                <Box sx={flexCenter}>
                    <DatePicker 
                            label='Select date from'
                            value={dateFrom}
                            onChange={(date) =>dispatch(setDateType({value: 'dateFrom', date})) }
                    />
                    <DatePicker 
                            label='Select date to'
                            value={dateTo}
                            onChange={(date) =>dispatch(setDateType({value: 'dateTo', date})) }
                    />
                </Box>
                <Box sx={flexCenter}>
                    <Box sx={{width: 300}}>
                            <Slider
                                value={hourseRange}
                                onChange={handleChange1}
                                valueLabelDisplay="auto"
                                getAriaValueText={valuetext}
                                max={23}
                                min={0}
                                marks={marks}
                                disableSwap
                            />
                        </Box>
                    </Box>
                <Box sx={flexCenter}>
                    <Button disabled={loadData} sx={{justifySelf: 'start'}} variant="outlined" onClick={handleSubmit}>Submit</Button>
                </Box>
            </Box>
        </LocalizationProvider>
    )
}

export default ProductFilter