import { createBrowserRouter, Navigate, useLocation } from "react-router-dom";
import Layout from "./pages/Layout";
import SaleDashboardPage from "./pages/SaleDashboardPage";
import ProductDashboardPage from "./pages/ProductDashboardPage";
import SalesAnalyticPage from "./pages/SalesAnalyticPage";
import AverageCheckPage from "./pages/AverageChackPage";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { logout } from "./store/authSlice";
import { checkTokenAction } from "./asyncActions/authActions";
import AuthPage from "./pages/AuthPage";




const PrivateRoute = ({ Component }) => {

  const dispatch = useDispatch()

  const {
      isAuthenticated
  } = useSelector(store => store.authorization)

  const {pathname} = useLocation()


  useEffect(() => {
      if (!isAuthenticated){
          dispatch(logout())            
      }
      dispatch(checkTokenAction())
  },[isAuthenticated, pathname])

  return isAuthenticated ? <Component/> : <Navigate to="/login" />;
};




export const routes = createBrowserRouter([
  {
    path: '/', 
    element: <Layout/>,
    children: [
        {path: '/', element: <PrivateRoute Component={SaleDashboardPage}></PrivateRoute>},
        {path: '/login', element: <AuthPage/>},
        {path: '/sales/table', element: <PrivateRoute Component={ProductDashboardPage}></PrivateRoute>},
        {path: '/sales/analytic', element: <PrivateRoute Component={SalesAnalyticPage}></PrivateRoute>},
        {path: '/sales/average_check', element: <PrivateRoute Component={AverageCheckPage}></PrivateRoute>},
    ]
    },  
])


