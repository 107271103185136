

export function totalPricePerDays(data){
    console.log(data);
    const allClosedSale = data.filter(el => el.status !== 'Չեղարկված')    
    let allSale = reduceData(allClosedSale)
    let onSpotSale = reduceData(allClosedSale.filter(el => el.delivery_type === 'Տեղում'))
    let onDeliverySale = reduceData(allClosedSale.filter(el => el.delivery_type === 'Առաքում'))
    let onBookingSale = reduceData(allClosedSale.filter(el => el.delivery_type === 'Ամրագրում'))    
    let avgCheck = (allClosedSale.reduce((accum, order) => accum + (+order.to_be_payed),0) / allClosedSale.length).toFixed(2)
    let times = Object
                  .keys(allSale)
                  .map(el => {
                      let elem = new Date(el)
                      elem.setHours(0)
                      return elem
                  })
                  .reverse()
      return {
        times,
        allSale: Object.values(allSale).reverse(),
        onSpotSale: Object.values(onSpotSale).reverse(),
        onDeliverySale: Object.values(onDeliverySale).reverse(),
        onBookingSale: Object.values(onBookingSale).reverse(),
        avgCheck
      }
}


function reduceData(array){
  return array.reduce((acc, sale) => {
    const date = sale.date.split(' ')[0]; 
    const amount = parseFloat(sale.to_be_payed);
    if (!acc[date]) {
      acc[date] = 0;
    } 
    acc[date] += amount;
    return acc;
  }, {})
}

// {
//     'Ավարտված', успешный
//     'Տեղում/Ամրագրում', 
//     'Առաքման փուլում ',
//     'Խմբավորված առաքման',
//     'Նոր պատվեր',
//     'Առաքման պատվերներ',
//     'Չեղարկված'
//   }
    