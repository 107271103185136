import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import AverageCheckFilter from "../components/AverageCheckFilter"
import AverageTable from "../components/AverageTable/AverageTable"
import { whiteBorder } from "../service/styles"
import { useDispatch, useSelector } from "react-redux"
import { setBranch } from "../store/avgCheckFilterSlice"
import { averageCheck } from "../asyncActions/ordersAction"






function AverageCheckPage(){

    const {
        loadData,
        data,
        branches,
        selectedBranch,
        dateTo,
        dateCompareTo,
        dateFrom,
        dateCompareFrom,
    } = useSelector(store => store.avgCheckFilterSlice)

    const dispatch = useDispatch()

    function handleChange(e){
        dispatch(setBranch(e.target.value))
        let dateBetween1 = {
            dateTo: dateTo.format('YYYY-MM-DD'),
            dateFrom: dateFrom.format('YYYY-MM-DD') 
         }
 
         let dateBetween2 = {
             dateTo: dateCompareTo.format('YYYY-MM-DD'),
             dateFrom: dateCompareFrom.format('YYYY-MM-DD')   
         }
        dispatch(averageCheck({dateBetween1, dateBetween2}))
    }

    return (
        <div>
            <AverageCheckFilter/>
            <Box sx={{...whiteBorder, alignItems: 'center'}}>
            {loadData ? 
                    <CircularProgress/> : 
                    (data.length !== 0) ? 
                        <>

                            <FormControl sx={{width: 200}}>
                                <InputLabel id="branchList">Select branch</InputLabel>
                                <Select
                                    labelId="branchList"
                                    id="demo-simple-select"
                                    value={selectedBranch}
                                    label="Select branch"
                                    onChange={handleChange}
                                >
                                    {branches.map(branch => <MenuItem key={branch} value={branch}>{branch}</MenuItem>)}
                                    <MenuItem value={'all'}>All</MenuItem>
                     
                                </Select>
                            </FormControl>
                            <AverageTable/> 
                        </>
                        
                        :
                    <Typography>No data</Typography>
                }
            </Box>

        </div>
    )
}

export default AverageCheckPage