
import { PieChart } from '@mui/x-charts/PieChart';


function PieDataChart(props){

    const {
        data
    } = props
 
    return(
        <PieChart
            series={[
                {data}
            ]}
            width={400}
            height={200}
        />
    )
}


export default PieDataChart