




import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { averageCheck, salesDataAnalytic } from "../asyncActions/ordersAction";
import { calculateAverages } from "../service/avgCheckCalc";


export const avgCheckFilterSlice = createSlice({
    name: 'avgCheckFilter',
    initialState: {
        dateFrom: dayjs().startOf('month'),
        dateTo: dayjs(),
        dateCompareFrom: dayjs(),
        dateCompareTo: dayjs(),
        data: [],
        branches: [],
        selectedBranch: 'all',
        loadData: false,
        error: null
    },
    reducers: {
        setDateType(state, action){
            state[action.payload.value] = action.payload.date
        },
        setBranch(state,action){
            state.selectedBranch = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(averageCheck.fulfilled,((state, action) => {
            state.loadData = false
            let result  = calculateAverages(action.payload.resData1, action.payload.resData2, state.selectedBranch)  
            state.data = result.averages
            if (state.selectedBranch === 'all'){
                state.branches = result.branches                  
            }                  
            state.error = null
        }))
        .addCase(averageCheck.rejected, ((state) => {
            state.data = {}
            state.loadData = false
            state.error = 'error'
        }))
        .addCase(averageCheck.pending, ((state) => {
            state.loadData = true
            state.data = []
            state.error = null
        }))
    }
})


export const {
    setDateType,
    setBranch
} = avgCheckFilterSlice.actions
export default avgCheckFilterSlice.reducer
