import { createAsyncThunk } from "@reduxjs/toolkit";
import { credKey, url } from "../service/creds"
import { orderUnit } from "../units/orderUnit";



export const fetchData = createAsyncThunk('get/data', orderUnit)

export const productData = createAsyncThunk('get/products', orderUnit)

export const salesDataAnalytic = createAsyncThunk('get/between/products', async ({dateBetween1, dateBetween2}) => {    
    let resData1 = await orderUnit(dateBetween1)
    let resData2 = await orderUnit(dateBetween2)
    return {resData1, resData2}
} )

export const averageCheck = createAsyncThunk('get/average/check', async ({dateBetween1, dateBetween2}) => {    
    let resData1 = await orderUnit(dateBetween1)
    let resData2 = await orderUnit(dateBetween2)
    return {resData1, resData2}
} )

